.tab-pane {
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(3, 320px);
	grid-gap: 24px;
	justify-content: space-evenly;
}

@media only screen and (max-width: 1100px) {
	.tab-pane {
		grid-template-columns: repeat(2, 320px);
	}
}

@media only screen and (max-width: 768px) {
	.tab-pane {
		grid-template-columns: 320px;
	}
}
